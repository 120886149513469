@import 'variables';

$toggle-item-size: 24px;
$toggle-item-gap: 4px;
$content-container-height: unset;
$editor-min-height: 300px;

.collaborative-editor-wrapper {
	position: relative;

	/* Toolbar */
	.collaborative-editor-toolbar {
		background-color: $toolbar-background-color;
		border: 1px solid $editor-border-color;
		border-bottom: none;
		border-radius: 8px 8px 0 0;
		padding: 12px 12px calc(12px - #{$toggle-item-gap}) 12px;

		&.show-on-focus {
			position: absolute;
			bottom: 100%;
		}

		.toolbar-toggle-item,
		.dropdown-toggle {
			margin-right: $toggle-item-gap;
			margin-bottom: $toggle-item-gap;
			border: none;
			height: $toggle-item-size !important;
			background-color: $toolbar-item-background-color !important;
			color: $toolbar-item-color !important;

			&.active {
				background-color: $toolbar-item-background-color-active !important;
			}

			&:hover:not(:disabled):not(.active) {
				background-color: rgba($color: $toolbar-item-background-color-active, $alpha: 0.5) !important;
			}

			&:focus {
				position: relative;
				box-shadow: 0 0 0 2px $toolbar-item-background-color-active;
			}
		}

		.toolbar-toggle-item {
			flex: 0 0 auto;
			width: $toggle-item-size !important;
			border-radius: 4px;
			padding: 0 5px;
			display: inline-flex;
			font-size: 13px;
			line-height: 1;
			align-items: center;
			justify-content: center;
		}

		.toolbar-separator {
			margin-top: calc(#{$toggle-item-gap} / 2);
			margin-right: $toggle-item-gap;
			width: 2px;
			height: calc(#{$toggle-item-size} - #{$toggle-item-gap});
			background-color: $toolbar-item-background-color-active;

			& + .toolbar-separator,
			&:last-child {
				display: none;
			}
		}

		.dropdown {
			& > .toolbar-toggle-item {
				margin-right: 0;
			}

			.dropdown-toggle {
				padding-top: 0;
				padding-bottom: 0;

				&.dropdown-toggle-split {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.dropdown-menu {
				&.horizontal {
					width: auto;

					&.show {
						display: flex;
					}

					&.icons-only .dropdown-item {
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
						width: 30px;
						padding: 8px;

						& > i.fa {
							margin: 0;
						}
					}
				}
			}
		}

		.emoji-container {
			position: relative;
			margin-right: $toggle-item-gap;
			margin-bottom: $toggle-item-gap;
			width: $toggle-item-size;
			height: $toggle-item-size;

			& > button {
				margin: 0 !important;
				width: 100%;
				height: 100% !important;
				padding: 0;
			}
		}
	}

	/* Editor */
	.ProseMirror {
		border: 1px solid $editor-border-color;
		padding: 16px;
		outline: none;

		&[contenteditable='true'] {
			min-height: $editor-min-height;
		}

		* {
			box-sizing: border-box;
		}

		p,
		h3 {
			word-break: break-word;
		}

		.editor-widget-preview {
			cursor: pointer;

			&.content-block {
				& > :first-child {
					border: 1px solid $editor-border-color;
					border-radius: 4px;
					overflow: auto;
				}

				.content-container {
					height: $content-container-height;
				}
			}

			&.widget-v1,
			&.widget-v2 {
				border: 1px solid $editor-border-color;
				border-radius: 4px;
				overflow: hidden;
			}

			.block-content {
				.content-image {
					max-width: 250px;
					max-height: 250px;
				}

				.gallery-title,
				.image-title {
					padding-left: 20px;
					overflow: hidden;
				}
			}
		}

		table {
			border-collapse: collapse;
			margin: 0;
			overflow: hidden;
			table-layout: fixed;
			width: 100%;

			td,
			th {
				border: 2px solid #ced4da;
				box-sizing: border-box;
				min-width: 1em;
				padding: 3px 5px;
				position: relative;
				vertical-align: top;

				> * {
					margin-bottom: 0;
				}
			}

			th {
				background-color: #f1f3f5;
				font-weight: bold;
				text-align: left;
			}

			.selectedCell:after {
				background: rgba(200, 200, 255, 0.4);
				content: '';
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				pointer-events: none;
				position: absolute;
				z-index: 2;
			}

			.column-resize-handle {
				background-color: #adf;
				bottom: -2px;
				position: absolute;
				right: -2px;
				pointer-events: none;
				top: 0;
				width: 4px;
			}

			p {
				margin: 0;
			}
		}

		blockquote {
			border-left: 3px solid rgba(#0d0d0d, 0.1);
			padding-left: 1rem;
		}

		&[contenteditable='false'] .editor-widget-preview {
			cursor: default;
		}

		mark {
			background-color: unset;

			&[class^='pen'] {
				padding: 0;
			}
		}

		li > p {
			margin-bottom: 0;
		}
	}
}

/* Toolbar pop-ups */
.collaborative-editor-toolbar-popover > .popover {
	z-index: 1111;

	.link-button + .link-button {
		margin-left: 8px;
	}

	.popover-primary-settings {
		display: flex;
		gap: 20px;
		align-items: center;
		margin-bottom: 8px;

		label {
			margin-bottom: unset;
		}
	}

	.popover-secondary-settings {
		display: grid;
		grid-template-columns: auto max-content;
		grid-template-rows: auto max-content;
		gap: 8px;
		align-items: center;
		margin-bottom: 8px;

		i {
			pointer-events: unset;
		}
	}

	.Label {
		font-size: 13px;
		width: 75px;
	}

	.Input {
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		border-radius: 4px;
		padding: 0 10px;
		font-size: 13px;
		line-height: 1;
		height: 25px;
	}

	.Text {
		margin: 0;
		font-size: 15px;
		line-height: 19px;
		font-weight: 500;
	}
}

/* Editor in a blocky */
.blocky .block {
	.ProseMirror {
		&[contenteditable='true'] {
			min-height: unset;
		}
	}
}
