@media (max-width: 800px) {
	.gallery-block-masonry-grid {
		margin-left: -15px; /* gutter size offset */
	}
}

.gallery-block-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	//margin-left: 20px; /* gutter size offset */
	justify-content: start;
	width: 100%;
	height: auto;
	margin-top: 20px; /* gutter size offset */

	.max-width-100 {
		width: 100%;
	}
}
.gallery-block-grid_column {
	margin-left: -15px;
	padding-left: 25px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.gallery-block-grid_column > img {
	/* change div to reference your elements you put in <Masonry> */
	background: grey;
	margin-bottom: 50px;
}

.gallery-block-missing-images {
	margin: auto;
}
