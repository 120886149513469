$image-size: 24px;

.controls-container {
	display: flex;
	flex-direction: column;
	border: 1px dashed #e2e2e2;
	padding: 10px 15px;
	width: 100%;

	.margin-b-1 {
		margin-bottom: 5px;
	}

	.custom-icon {
		display: inline-block;
		width: 16px;
		height: 16px;
		background-size: contain;
		background-repeat: no-repeat;
		transition: filter 0.15s ease-in-out;
	}

	button {
		.fa,
		.custom-icon {
			margin-right: 4px;
			margin-left: unset;
			width: 16px;
		}
	}

	.general-controls {
		align-self: flex-start;
		width: 100%;

		&:first-child {
			padding-bottom: 10px;
		}

		&:last-child {
			padding-top: 10px;
		}

		.controls-container-info {
			font-size: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			float: right;
		}

		button.with-custom-icon {
			display: inline-flex;
			align-items: center;

			&:hover .custom-icon {
				filter: brightness(0) #{'invert()'};
			}
		}
	}

	.control-container-tags {
		border: 1px solid #b2b3b5;
		border-right: none;
		border-left: none;
		align-self: auto;
		max-width: 1000px;
		display: inline-block;
		padding-top: 16px;
		padding-bottom: 11px;

		&:empty {
			padding: 12px 0;
		}
	}

	.dropdown-menu {
		max-height: 270px;
		width: max-content;
		overflow-y: scroll;

		.dropdown-item {
			.custom-icon {
			}
		}
	}

	.more-occurrence-dropdown,
	.insert-occurrence-dropdown {
		border-left: none;
		border-radius: 0;
		font-size: small;
		font-weight: 600;
		height: 38px !important;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		border-right: 1px solid #000;
		border-bottom-right-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}

	.insert-occurrence-dropdown.dropdown-toggle::after {
		margin-left: unset;
	}

	.additional-tags-dropdown-item,
	.additional-options-dropdown-item {
		padding: 8px 16px;
	}

	.additional-options-dropdown-item {
		display: flex;
		align-items: center;

		.custom-icon {
			filter: brightness(0);
		}

		.fa {
			color: black;
		}
	}

	.first-occurrence {
		display: flex;
		align-items: center;
		color: #000000;

		&.btn-outline-secondary:not(.disabled):hover {
			background-color: #f5f5f5;
			color: #000000;
		}

		.option-logo {
			margin-right: 4px;
		}
	}

	.additional-tags-dropdown-item {
		display: grid;
		grid-template-columns: $image-size 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'image name'
			'image secondary';
		column-gap: 8px;

		.additional-tags-dropdown-item-name {
			grid-area: name;
		}

		.additional-tags-dropdown-item-secondary-info {
			display: flex;
			grid-area: secondary;
			color: #8c8c8c;
			font-size: 11px;

			& > div {
				display: inline-flex;
				margin-right: 4px;

				&.additional-tags-dropdown-item-sport {
					.additional-tags-dropdown-item-sport-icon {
						margin-right: 2px;
						width: 16px;
						height: 16px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}
	}

	.option-logo {
		grid-area: image;
		width: $image-size;
		height: $image-size;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&.option-logo-player {
			border-radius: 50%;
		}
	}
}
